import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Collapse, FloatingLabel, FormLabel, Toast, ToastBody, InputGroup } from 'react-bootstrap';
import SummaryPills from './SummaryPills';
import axios from 'axios';

const AddRecipeForm = ({ onRecipeAdded, cuisineOptions, proteinOptions }) => {
  const [recipeName, setRecipeName] = useState('');
  const [cuisine, setCuisine] = useState('');
  const [protein, setProtein] = useState('');
  const [carb, setCarb] = useState('');
  const [effort, setEffort] = useState('Medium');
  const [recipeImage, setRecipeImage] = useState(null);
  const [url, setUrl] = useState('');
  const [book, setBook] = useState('');
  const [page, setPage] = useState('');
  const [previewImageOpen, setPreviewImageOpen] = useState(false);
  const [autoCompleteData, setAutoCompleteData] = useState({});
  const [showToast, setShowToast] = useState(false);

  const effortLevel = ['Low', 'Medium', 'High'];

  useEffect(() => {
    if (url) {
      try {
        new URL(url);

        axios.get('/auto-complete-recipe', { params: { url } })
          .then(response => {
            console.log(response.data);
            setAutoCompleteData(response.data);
          });
      } catch {
        console.log('not a valid url. skipping.')
        setShowToast(true)
      }
    }
  }, [url]);

  useEffect(() => {
    if (autoCompleteData.predictedTitle) {
      setRecipeName(autoCompleteData.predictedTitle);
    }
    if (autoCompleteData.predictedProtein) {
      setProtein(autoCompleteData.predictedProtein);
    }
    if (autoCompleteData.predictedCuisine) {
      setCuisine(autoCompleteData.predictedCuisine);
    }
  }, [autoCompleteData])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newItem = { recipeName, cuisine, protein, url, book, page, carb, effort, image: recipeImage };
    await axios.post('/add-recipe', newItem)
      .then(response => {
        setRecipeName('');
        setCuisine('');
        setProtein('');
        setUrl('');
        setBook('');
        setEffort('');
        setCarb('');
        setPage('');
        onRecipeAdded({ ...newItem, name: recipeName });
      }).catch(error => {
        alert('Error adding recipe:', error);
      });
  };

  return (
    <>
      <Toast bg='danger' className='text-white' show={showToast} onClose={() => setShowToast(false)} autohide delay={3000}>
        <ToastBody>Error Fetching Meta from URL </ToastBody>
      </Toast>

      <Container>
        <Form onSubmit={handleSubmit}>

          <Form.Group as={Row} className='mt-3' controlId="formRecipeName">
            <Col sm="12">
              <FloatingLabel label='Recipe Name'>
                <Form.Control
                  type="text"
                  value={recipeName}
                  onChange={(e) => setRecipeName(e.target.value)}
                  required
                />
              </FloatingLabel>
            </Col>
          </Form.Group>

          <Form.Group className='px-2 mt-4'>
            <FormLabel className='pt-2'> Source </FormLabel>
            <Form.Group as={Row} className='my-2' controlId="formUrl">
              <Col sm="12">
                <FloatingLabel label='URL'>
                  <Form.Control
                    type="text"
                    value={url}
                    onChange={(e) => { setUrl(e.target.value) }}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='my-2' controlId="formBook">
              <Col sm="12">
                <Row>
                  <Col xs={8} md="9">
                    <FloatingLabel label='Book'>
                      <Form.Control
                        type="text"
                        value={book}
                        onChange={(e) => { setBook(e.target.value) }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col  xs={4} md="3">
                    <FloatingLabel label='Page'>
                      <Form.Control
                        type="text"
                        value={page}
                        onChange={(e) => { setPage(e.target.value) }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </Form.Group>

          <Form.Group className='mt-3 px-2'>
            <FormLabel className='pt-2'> Meta </FormLabel>
            <Form.Group as={Row} className='my-2' controlId="formCuisine">
              <Col sm="12">
                <FloatingLabel label='Cuisine'>
                  <Form.Control
                    type="text"
                    value={cuisine}
                    onChange={(e) => setCuisine(e.target.value)}
                    required
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='my-2' controlId="formProtein">
              <Col sm="12">
                <FloatingLabel label='Protein'>
                  <Form.Control
                    type="text"
                    value={protein}
                    onChange={(e) => setProtein(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='my-2' controlId="formCarb">
              <Col sm="12">
                <FloatingLabel label='Carb'>
                  <Form.Control
                    type="text"
                    value={carb}
                    onChange={(e) => setCarb(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>
          </Form.Group>

          <Form.Group as={Row} className='mt-4' controlId="formEffort">
            <Col sm="12">
              <Row>
                <Col xs={9} sm={9}>
                  <Form.Label>Low</Form.Label>
                </Col>
                <Col xs={3} sm={3}>
                  <Form.Label className='text-nowrap'>High</Form.Label>
                </Col>
              </Row>
              <Form.Range max={2} defaultValue={1} onChange={(event) => setEffort(effortLevel[event.target.value])} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mt-4' controlId="formEffort">
            <Form.Label >
              <SummaryPills pillContent={[protein, cuisine, carb]} effort={effort} />
            </Form.Label>
          </Form.Group>
          {autoCompleteData.imageOptions && autoCompleteData.imageOptions.length > 0 && (
            <Form.Group as={Row}>
              <Button className='bg-secondary' onClick={() => setPreviewImageOpen(!previewImageOpen)}>
                Select Image
              </Button >
              <Collapse in={previewImageOpen}>

                <Row>
                  {autoCompleteData.imageOptions.map((image, index) =>
                    <Row key={index}>
                      <img className={'my-4 ' + (image === recipeImage ? 'bg-success' : 'bg-light')} src={image} onClick={() => setRecipeImage(image)} />
                    </Row>
                  )}

                </Row>
              </Collapse>
            </Form.Group>
          )}
          <Row className='text-right my-2'>
            <Button className='float-end' variant="success" type="submit">
              Add Recipe
            </Button>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default AddRecipeForm;
